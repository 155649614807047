/** @type {import('./types/harmony-config').IHarmonyConfig} */
const CONFIG = {
    title: 'NH Pay',
    description: 'NH포인트로 슬기로운 구독생활, 농카구독',
    domain: 'nh-pay',
    membership: 'nhpay',
    memberGroup: '',
    pointSystem: '',
    testEmail: 'super@freedgrouptech.com',
    testPassword: 'asdf123',
    linkedPage: '',
    locales: ['ko'],
    defaultLocale: 'ko',
    defaultCurrency: 'KRW',
    backgroundColor: '#F7F9FA',
    primaryFont: 'apple',
    secondaryFont: 'poppins',
    marketplaceTemplate: ['tangible', 'intangible'],
    loginType: ['membership'],
    isPwa: false,
    // Keep at bottom of file for pipeline build
    designTemplate: 'designB',
};

module.exports = CONFIG;
